import * as actionTypes from './actionTypes';
import { VehicleActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Vehicle } from '../../domain/Vehicle';

export type VehicleStateType = {
  vehiclesList: ListResults<Vehicle> | null;
  vehiclesListLoading: boolean;
  vehiclesListError: HttpError;
  vehiclesListUpdateNeeded: boolean;
  allVehicles: Vehicle[];
  allVehiclesLoading: boolean;
  allVehiclesError: HttpError;
  vehicleCreateLoading: boolean;
  vehicleCreateError: HttpError;
  vehicleCreateSuccess: boolean;
  createdVehicle: Vehicle | null;
  vehicleUpdateLoading: boolean;
  vehicleUpdateError: HttpError;
  vehicleUpdateSuccess: boolean;
  changeVehicleStatusLoading: boolean;
  changeVehicleStatusError: HttpError;
  changeVehicleStatusSuccess: boolean;
  vehicle: Vehicle | null;
  vehicleLoading: boolean;
  vehicleError: HttpError;
  vehicleDeleteLoading: boolean;
  vehicleDeleteError: HttpError;
  vehicleDeleteSuccess: boolean;
};

export type VehicleActionType = VehicleStateType & {
  type: VehicleActionTypes;
};

export const initialState: VehicleStateType = {
  vehiclesList: null,
  vehiclesListLoading: true,
  vehiclesListError: null,
  vehiclesListUpdateNeeded: false,
  vehicleCreateLoading: false,
  vehicleCreateError: null,
  vehicleCreateSuccess: false,
  createdVehicle: null,
  vehicleUpdateLoading: false,
  vehicleUpdateError: null,
  vehicleUpdateSuccess: false,
  changeVehicleStatusLoading: false,
  changeVehicleStatusError: null,
  changeVehicleStatusSuccess: false,
  vehicle: null,
  vehicleLoading: false,
  vehicleError: null,
  vehicleDeleteLoading: false,
  vehicleDeleteError: null,
  vehicleDeleteSuccess: false,
  allVehicles: [],
  allVehiclesLoading: false,
  allVehiclesError: null,
};

const fetchVehiclesListStart = (state: VehicleStateType): VehicleStateType => ({
  ...state,
  vehiclesListLoading: true,
});

const fetchVehiclesListSuccess = (
  state: VehicleStateType,
  action: VehicleActionType,
): VehicleStateType => ({
  ...state,
  vehiclesListLoading: false,
  vehiclesList: action.vehiclesList,
  vehiclesListUpdateNeeded: false,
  vehicleCreateSuccess: false,
});

const fetchVehiclesListFail = (
  state: VehicleStateType,
  action: VehicleActionType,
): VehicleStateType => ({
  ...state,
  vehiclesListError: action.vehiclesListError,
  vehiclesListLoading: false,
});

const fetchVehicleStart = (state: VehicleStateType): VehicleStateType => ({
  ...state,
  vehicleLoading: true,
});

const fetchVehicleSuccess = (
  state: VehicleStateType,
  action: VehicleActionType,
): VehicleStateType => ({
  ...state,
  vehicle: action.vehicle,
  vehicleLoading: false,
});

const fetchVehicleFail = (
  state: VehicleStateType,
  action: VehicleActionType,
): VehicleStateType => ({
  ...state,
  vehiclesListError: action.vehiclesListError,
  vehicleLoading: false,
});

const createVehicleStart = (state: VehicleStateType): VehicleStateType => ({
  ...state,
  vehicleCreateLoading: true,
});

const createVehicleSuccess = (
  state: VehicleStateType,
  action: VehicleActionType,
): VehicleStateType => ({
  ...state,
  vehicleCreateLoading: false,
  vehicleCreateSuccess: true,
  createdVehicle: action.createdVehicle,
  vehiclesListUpdateNeeded: true,
});

const createVehicleFail = (
  state: VehicleStateType,
  action: VehicleActionType,
): VehicleStateType => ({
  ...state,
  vehicleCreateLoading: false,
  vehicleCreateError: action.vehicleCreateError,
});

const updateVehicleStart = (state: VehicleStateType): VehicleStateType => ({
  ...state,
  vehicleUpdateLoading: true,
  vehicleCreateSuccess: false,
  vehicleUpdateSuccess: false,
});

const updateVehicleSuccess = (state: VehicleStateType): VehicleStateType => ({
  ...state,
  vehicleUpdateLoading: false,
  vehicleUpdateSuccess: true,
  vehiclesListUpdateNeeded: true,
});

const updateVehicleFail = (
  state: VehicleStateType,
  action: VehicleActionType,
): VehicleStateType => ({
  ...state,
  vehicleUpdateLoading: false,
  vehicleUpdateError: action.vehicleUpdateError,
});

const changeVehicleStatusStart = (
  state: VehicleStateType,
): VehicleStateType => ({
  ...state,
  changeVehicleStatusLoading: true,
});

const changeVehicleStatusSuccess = (
  state: VehicleStateType,
): VehicleStateType => ({
  ...state,
  changeVehicleStatusLoading: false,
  changeVehicleStatusError: null,
  changeVehicleStatusSuccess: true,
  vehiclesListUpdateNeeded: true,
});

const changeVehicleStatusFail = (
  state: VehicleStateType,
  action: VehicleActionType,
): VehicleStateType => ({
  ...state,
  changeVehicleStatusLoading: false,
  changeVehicleStatusError: action.changeVehicleStatusError,
});

const vehicleDeleteStart = (state: VehicleStateType): VehicleStateType => ({
  ...state,
  vehicleDeleteLoading: true,
});

const vehicleDeleteSuccess = (state: VehicleStateType): VehicleStateType => ({
  ...state,
  vehicleDeleteLoading: false,
  vehicleDeleteSuccess: true,
  vehicleDeleteError: null,
  vehiclesListUpdateNeeded: true,
});

const vehicleDeleteError = (
  state: VehicleStateType,
  action: VehicleActionType,
): VehicleStateType => ({
  ...state,
  vehicleDeleteLoading: false,
  vehicleDeleteError: action.vehicleDeleteError,
});

const resetCreatedVehicle = (state: VehicleStateType): VehicleStateType => ({
  ...state,
  createdVehicle: null,
  vehicleCreateLoading: false,
  vehicleCreateSuccess: false,
  vehicleCreateError: null,
  vehiclesListUpdateNeeded: false,
});

const resetUpdatedVehicle = (state: VehicleStateType): VehicleStateType => ({
  ...state,
  vehicle: null,
  vehicleLoading: false,
  vehicleError: null,
  vehicleUpdateLoading: false,
  vehicleUpdateSuccess: false,
  vehicleUpdateError: null,
  vehiclesListUpdateNeeded: false,
});

const fetchAllVehiclesStart = (state: VehicleStateType): VehicleStateType => ({
  ...state,
  allVehiclesLoading: true,
});

const fetchAllVehiclesSuccess = (
  state: VehicleStateType,
  action: VehicleActionType,
): VehicleStateType => ({
  ...state,
  vehiclesListLoading: false,
  allVehicles: action.allVehicles,
  allVehiclesError: null,
});

const fetchAllVehiclesFail = (
  state: VehicleStateType,
  action: VehicleActionType,
): VehicleStateType => ({
  ...state,
  allVehiclesError: action.allVehiclesError,
  allVehiclesLoading: false,
});

const resetVehicleStore = (): VehicleStateType => ({
  ...initialState,
});

const completelyResetVehicleStore = (): VehicleStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: VehicleActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_VEHICLES_LIST_START:
      return fetchVehiclesListStart(state);
    case actionTypes.FETCH_VEHICLES_LIST_SUCCESS:
      return fetchVehiclesListSuccess(state, action);
    case actionTypes.FETCH_VEHICLES_LIST_FAIL:
      return fetchVehiclesListFail(state, action);
    case actionTypes.FETCH_VEHICLE_START:
      return fetchVehicleStart(state);
    case actionTypes.FETCH_VEHICLE_SUCCESS:
      return fetchVehicleSuccess(state, action);
    case actionTypes.FETCH_VEHICLE_FAIL:
      return fetchVehicleFail(state, action);
    case actionTypes.CREATE_VEHICLE_START:
      return createVehicleStart(state);
    case actionTypes.CREATE_VEHICLE_SUCCESS:
      return createVehicleSuccess(state, action);
    case actionTypes.CREATE_VEHICLE_FAIL:
      return createVehicleFail(state, action);
    case actionTypes.UPDATE_VEHICLE_START:
      return updateVehicleStart(state);
    case actionTypes.UPDATE_VEHICLE_SUCCESS:
      return updateVehicleSuccess(state);
    case actionTypes.UPDATE_VEHICLE_FAIL:
      return updateVehicleFail(state, action);
    case actionTypes.CHANGE_VEHICLE_STATUS_START:
      return changeVehicleStatusStart(state);
    case actionTypes.CHANGE_VEHICLE_STATUS_SUCCESS:
      return changeVehicleStatusSuccess(state);
    case actionTypes.CHANGE_VEHICLE_STATUS_FAIL:
      return changeVehicleStatusFail(state, action);
    case actionTypes.DELETE_VEHICLE_START:
      return vehicleDeleteStart(state);
    case actionTypes.DELETE_VEHICLE_SUCCESS:
      return vehicleDeleteSuccess(state);
    case actionTypes.DELETE_VEHICLE_FAIL:
      return vehicleDeleteError(state, action);
    case actionTypes.RESET_VEHICLE_STORE:
      return resetVehicleStore();
    case actionTypes.FETCH_ALL_VEHICLES_START:
      return fetchAllVehiclesStart(state);
    case actionTypes.FETCH_ALL_VEHICLES_SUCCESS:
      return fetchAllVehiclesSuccess(state, action);
    case actionTypes.FETCH_ALL_VEHICLES_FAIL:
      return fetchAllVehiclesFail(state, action);
    case actionTypes.RESET_UPDATED_VEHICLE:
      return resetUpdatedVehicle(state);
    case actionTypes.RESET_CREATED_VEHICLE:
      return resetCreatedVehicle(state);
    case actionTypes.LOGOUT:
      return completelyResetVehicleStore();
    default:
      return state;
  }
};

export default reducer;
