import * as actionTypes from './actionTypes';
import { LogGroupDayActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { LogGroupDay } from '../../domain/LogGroupDay';

export type LogGroupDayStateType = {
  logGroupDays: ListResults<LogGroupDay> | null;
  logGroupDaysLoading: boolean;
  logGroupDaysError: HttpError;
};

export type LogGroupDayActionType = LogGroupDayStateType & {
  type: LogGroupDayActionTypes;
};

export const initialState: LogGroupDayStateType = {
  logGroupDays: null,
  logGroupDaysLoading: true,
  logGroupDaysError: null,
};

const fetchLogGroupDaysStart = (
  state: LogGroupDayStateType,
): LogGroupDayStateType => ({
  ...state,
  logGroupDaysLoading: true,
});

const fetchLogGroupDaysSuccess = (
  state: LogGroupDayStateType,
  action: LogGroupDayStateType,
): LogGroupDayStateType => ({
  ...state,
  logGroupDaysLoading: false,
  logGroupDaysError: null,
  logGroupDays: action.logGroupDays,
});

const fetchLogGroupDaysFail = (
  state: LogGroupDayStateType,
  action: LogGroupDayStateType,
): LogGroupDayStateType => ({
  ...state,
  logGroupDaysError: action.logGroupDaysError,
  logGroupDaysLoading: false,
});

const logout = (): LogGroupDayStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: LogGroupDayActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_LOG_GROUP_DAYS_START:
      return fetchLogGroupDaysStart(state);
    case actionTypes.FETCH_LOG_GROUP_DAYS_SUCCESS:
      return fetchLogGroupDaysSuccess(state, action);
    case actionTypes.FETCH_LOG_GROUP_DAYS_FAIL:
      return fetchLogGroupDaysFail(state, action);
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
