import React, { ReactNode } from 'react';
import styles from './FormCard.module.scss';
import cx from 'classnames';

type Props = {
  children: ReactNode;
  withShadow?: boolean;
  className?: string;
};

export const FormCard = ({ children, withShadow = true, className }: Props) => {
  return (
    <div
      className={cx(styles.formCard, className, {
        [styles.withShadow]: withShadow,
      })}
    >
      {children}
    </div>
  );
};

export default FormCard;
