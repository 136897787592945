import * as actionTypes from './actionTypes';
import { CertificationActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';

export type CertificationStateType = {
  certificationSignatureLoading: boolean;
  certificationSignatureError: HttpError;
  certificationSignature: Blob | null;
};

export type CertificationActionType = CertificationStateType & {
  type: CertificationActionTypes;
  isFilteredLogs: boolean;
};

export const initialState: CertificationStateType = {
  certificationSignatureError: null,
  certificationSignatureLoading: false,
  certificationSignature: null,
};

const fetchCertificationSignatureStart = (
  state: CertificationStateType,
): CertificationStateType => ({
  ...state,
  certificationSignatureLoading: true,
});

const fetchCertificationSignatureSuccess = (
  state: CertificationStateType,
  action: CertificationActionType,
): CertificationStateType => ({
  ...state,
  certificationSignatureLoading: false,
  certificationSignature: action.certificationSignature,
});

const fetchCertificationSignatureFail = (
  state: CertificationStateType,
  action: CertificationActionType,
): CertificationStateType => ({
  ...state,
  certificationSignatureError: action.certificationSignatureError,
  certificationSignatureLoading: false,
});

const resetCertificationStore = (): CertificationStateType => ({
  ...initialState,
});

const completelyResetCertificationStore = (): CertificationStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: CertificationActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_CERTIFICATION_SIGNATURE_START:
      return fetchCertificationSignatureStart(state);
    case actionTypes.FETCH_CERTIFICATION_SIGNATURE_SUCCESS:
      return fetchCertificationSignatureSuccess(state, action);
    case actionTypes.FETCH_CERTIFICATION_SIGNATURE_FAIL:
      return fetchCertificationSignatureFail(state, action);
    case actionTypes.RESET_CERTIFICATION_STORE:
      return resetCertificationStore();
    case actionTypes.LOGOUT:
      return completelyResetCertificationStore();
    default:
      return state;
  }
};

export default reducer;
