import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { FMCSAReportActionTypes } from './actionTypes';
import { FMCSAReport } from '../../domain/FMCSAReport';

export type FMCSAReportStateType = {
  FMCSAReportsList: ListResults<FMCSAReport> | null;
  FMCSAReportsListLoading: boolean;
  FMCSAReportsListError: HttpError;
  sendFMSAREportLoading: boolean;
  sendFMCSAReportSuccess: boolean;
  sendFMCSAReportError: HttpError;
};

export type FMCSAReportActionType = FMCSAReportStateType & {
  type: FMCSAReportActionTypes;
};

export const initialState: FMCSAReportStateType = {
  FMCSAReportsList: null,
  FMCSAReportsListLoading: true,
  FMCSAReportsListError: null,
  sendFMSAREportLoading: false,
  sendFMCSAReportSuccess: false,
  sendFMCSAReportError: null,
};

const fetchFMCSAReportsListStart = (
  state: FMCSAReportStateType,
): FMCSAReportStateType => ({
  ...state,
  FMCSAReportsListLoading: true,
});

const fetchFMCSAReportsListSuccess = (
  state: FMCSAReportStateType,
  action: FMCSAReportActionType,
): FMCSAReportStateType => ({
  ...state,
  FMCSAReportsListLoading: false,
  FMCSAReportsList: action.FMCSAReportsList,
});

const fetchFMCSAReportsListFail = (
  state: FMCSAReportStateType,
  action: FMCSAReportActionType,
): FMCSAReportStateType => ({
  ...state,
  FMCSAReportsListError: action.FMCSAReportsListError,
  FMCSAReportsListLoading: false,
});

const sendFMCSAReportStart = (
  state: FMCSAReportStateType,
): FMCSAReportStateType => ({
  ...state,
  sendFMSAREportLoading: true,
});

const sendFMCSAReportSuccess = (
  state: FMCSAReportStateType,
): FMCSAReportStateType => ({
  ...state,
  sendFMSAREportLoading: false,
  sendFMCSAReportSuccess: true,
});

const sendFMCSAReportFail = (
  state: FMCSAReportStateType,
  action: FMCSAReportActionType,
): FMCSAReportStateType => ({
  ...state,
  sendFMCSAReportError: action.sendFMCSAReportError,
  sendFMSAREportLoading: false,
  sendFMCSAReportSuccess: false,
});

const resetFMCSAReportStore = (): FMCSAReportStateType => ({
  ...initialState,
});

const completelyResetFMCSAReportStore = (): FMCSAReportStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: FMCSAReportActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_FMCSA_REPORTS_LIST_START:
      return fetchFMCSAReportsListStart(state);
    case actionTypes.FETCH_FMCSA_REPORTS_LIST_SUCCESS:
      return fetchFMCSAReportsListSuccess(state, action);
    case actionTypes.FETCH_FMCSA_REPORTS_LIST_FAIL:
      return fetchFMCSAReportsListFail(state, action);
    case actionTypes.SEND_FMCSA_REPORT_START:
      return sendFMCSAReportStart(state);
    case actionTypes.SEND_FMCSA_REPORT_SUCCESS:
      return sendFMCSAReportSuccess(state);
    case actionTypes.SEND_FMCSA_REPORT_FAIL:
      return sendFMCSAReportFail(state, action);
    case actionTypes.RESET_FMCSA_REPORT_STORE:
      return resetFMCSAReportStore();
    case actionTypes.LOGOUT:
      return completelyResetFMCSAReportStore();
    default:
      return state;
  }
};

export default reducer;
