export const UPDATE_DRIVER_SETTINGS_START = 'UPDATE_DRIVER_SETTINGS_START';
export const UPDATE_DRIVER_SETTINGS_SUCCESS = 'UPDATE_DRIVER_SETTINGS_START';
export const UPDATE_DRIVER_SETTINGS_FAIL = 'UPDATE_DRIVER_SETTINGS_FAIL';

export const RESET_DRIVER_SETTINGS_STORE = 'RESET_DRIVER_SETTINGS_STORE';
export const LOGOUT = 'LOGOUT';

export type DriverSettingsActionTypes =
  | typeof UPDATE_DRIVER_SETTINGS_START
  | typeof UPDATE_DRIVER_SETTINGS_SUCCESS
  | typeof UPDATE_DRIVER_SETTINGS_FAIL
  | typeof RESET_DRIVER_SETTINGS_STORE
  | typeof LOGOUT;
