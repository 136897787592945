export const FETCH_VEHICLES_LIST_START = 'FETCH_VEHICLES_LIST_START';
export const FETCH_VEHICLES_LIST_SUCCESS = 'FETCH_VEHICLES_LIST_SUCCESS';
export const FETCH_VEHICLES_LIST_FAIL = 'FETCH_VEHICLES_LIST_FAIL';

export const FETCH_VEHICLE_START = 'FETCH_VEHICLE_START';
export const FETCH_VEHICLE_SUCCESS = 'FETCH_VEHICLE_SUCCESS';
export const FETCH_VEHICLE_FAIL = 'FETCH_VEHICLE_FAIL';

export const FETCH_ALL_VEHICLES_START = 'FETCH_ALL_VEHICLES_START';
export const FETCH_ALL_VEHICLES_SUCCESS = 'FETCH_ALL_VEHICLES_SUCCESS';
export const FETCH_ALL_VEHICLES_FAIL = 'FETCH_ALL_VEHICLES_FAIL';

export const CREATE_VEHICLE_START = 'CREATE_VEHICLE_START';
export const CREATE_VEHICLE_SUCCESS = 'CREATE_VEHICLE_SUCCESS';
export const CREATE_VEHICLE_FAIL = 'CREATE_VEHICLE_FAIL';

export const UPDATE_VEHICLE_START = 'UPDATE_VEHICLE_START';
export const UPDATE_VEHICLE_SUCCESS = 'UPDATE_VEHICLE_SUCCESS';
export const UPDATE_VEHICLE_FAIL = 'UPDATE_VEHICLE_FAIL';

export const CHANGE_VEHICLE_STATUS_START = 'CHANGE_VEHICLE_STATUS_START';
export const CHANGE_VEHICLE_STATUS_SUCCESS = 'CHANGE_VEHICLE_STATUS_SUCCESS';
export const CHANGE_VEHICLE_STATUS_FAIL = 'CHANGE_VEHICLE_STATUS_FAIL';

export const DELETE_VEHICLE_START = 'DELETE_VEHICLE_START';
export const DELETE_VEHICLE_SUCCESS = 'DELETE_VEHICLE_SUCCESS';
export const DELETE_VEHICLE_FAIL = 'DELETE_VEHICLE_FAIL';

export const RESET_VEHICLE_STORE = 'RESET_VEHICLE_STORE';
export const RESET_CREATED_VEHICLE = 'RESET_CREATED_VEHICLE';
export const RESET_UPDATED_VEHICLE = 'RESET_UPDATED_VEHICLE';
export const LOGOUT = 'LOGOUT';

export type VehicleActionTypes =
  | typeof FETCH_VEHICLES_LIST_START
  | typeof FETCH_VEHICLES_LIST_SUCCESS
  | typeof FETCH_VEHICLES_LIST_FAIL
  | typeof FETCH_VEHICLE_START
  | typeof FETCH_VEHICLE_SUCCESS
  | typeof FETCH_VEHICLE_FAIL
  | typeof FETCH_ALL_VEHICLES_START
  | typeof FETCH_ALL_VEHICLES_SUCCESS
  | typeof FETCH_ALL_VEHICLES_FAIL
  | typeof CREATE_VEHICLE_START
  | typeof CREATE_VEHICLE_SUCCESS
  | typeof CREATE_VEHICLE_FAIL
  | typeof UPDATE_VEHICLE_START
  | typeof UPDATE_VEHICLE_SUCCESS
  | typeof UPDATE_VEHICLE_FAIL
  | typeof CHANGE_VEHICLE_STATUS_START
  | typeof CHANGE_VEHICLE_STATUS_SUCCESS
  | typeof CHANGE_VEHICLE_STATUS_FAIL
  | typeof DELETE_VEHICLE_START
  | typeof DELETE_VEHICLE_SUCCESS
  | typeof DELETE_VEHICLE_FAIL
  | typeof RESET_VEHICLE_STORE
  | typeof RESET_CREATED_VEHICLE
  | typeof RESET_UPDATED_VEHICLE
  | typeof LOGOUT;
