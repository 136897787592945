export const FETCH_LOG_GROUP_DAYS_START = 'FETCH_LOG_GROUP_DAYS_START';
export const FETCH_LOG_GROUP_DAYS_SUCCESS = 'FETCH_LOG_GROUP_DAYS_SUCCESS';
export const FETCH_LOG_GROUP_DAYS_FAIL = 'FETCH_LOG_GROUP_DAYS_FAIL';

export const LOGOUT = 'LOGOUT';

export type LogGroupDayActionTypes =
  | typeof FETCH_LOG_GROUP_DAYS_START
  | typeof FETCH_LOG_GROUP_DAYS_SUCCESS
  | typeof FETCH_LOG_GROUP_DAYS_FAIL
  | typeof LOGOUT;
