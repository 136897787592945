import React, {
  ChangeEvent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Autocomplete as MuiAutocomplete,
  Checkbox,
  Chip,
  CircularProgress,
  ClickAwayListener,
  FormControlLabel,
  InputAdornment,
  InputBaseProps,
  MenuItem,
  Popover,
  Switch,
  TextField as MuiTextField,
} from '@mui/material';
import styles from './TextField.module.scss';
import cx from 'classnames';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';
import Alert from '../Alert/Alert';
import useDelayedSearch, {
  UseDelayedSearch,
} from '../../hooks/useDelayedSearch/useDelayedSearch';
import { Asset } from '../../domain/Asset';
import Button from '../Button/Button';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import Loader from '../Loader/Loader';
import { translate } from '../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import { Editor } from '@tinymce/tinymce-react';
import FileInput from '../FileInput/FileInput';
import { HttpError } from '../../config/Axios/axios-instance';
import * as assetService from '../../store/asset/service';
import { enGB, lt } from 'date-fns/esm/locale';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { Locale } from '../../domain/Translation';
import {
  faCircleExclamation,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from '../Label/Label';

export type AutocompleteOptionProps = {
  label: string;
  value: string;
};

type Props = {
  selectedLocale: Locale;
  onChange?: (event: ChangeEvent<any>) => void;
  onFileChange?: (event: ChangeEvent<any>) => void;
  onCheckboxChange?: (name: string, value: string) => void;
  onInputValueChange?: (name: string, value: string) => void;
  onTimeChange?: (name: string, value: string) => void;
  onBlur?: (event: ChangeEvent<any>, freeSolo?: boolean) => void;
  onInputBlur?: (name: string) => void;
  onImageDelete?: (name: string, value: string | File) => void;
  onSetValidationErrors?: (error: HttpError) => void;
  value?: string | string[] | File | File[];
  label?: string | ReactNode;
  errors?: Array<string>;
  name: string;
  type?: string;
  placeholder?: string;
  inputProps?: Partial<StandardInputProps>;
  timeInputProps?: InputBaseProps['inputProps'];
  className?: string;
  options?: Array<{ value: string; label: string; isTerminated?: boolean }>;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  variant?: 'filled' | 'outlined' | 'standard';
  isOutlined?: boolean;
  disabled?: boolean;
  helperText?: string;
  clearable?: boolean;
  multiple?: boolean;
  disableCloseOnSelect?: boolean;
  disableClearable?: boolean;
  onSelectChange?: (
    value: string | string[],
    name: string,
    freeSolo?: boolean,
  ) => void;
  isLoading?: boolean;
  onSearch?: (searchValue: string) => void;
  moreLabel?: string;
  asset?: Asset | null;
  creatable?: boolean;
  onCreate?: () => void;
  autoFocus?: boolean;
  isSmall?: boolean;
  maxFileCount?: number;
  onFileDropped?: (name: string, files: File[]) => void;
  freeSolo?: boolean;
  limit?: number;
  onShowMoreClick?: () => void;
  richTextProps?: { toolbar?: string; menubar?: boolean; height?: number };
  onFocus?: (event: ChangeEvent<any>) => void;
  leaveDefaultEndAdornment?: boolean;
  maxDate?: string;
  minDate?: string;
  selectAll?: boolean;
  onSelectAll?: () => void;
  timezone: string;
  isDateControlsVisible?: boolean;
};

const TextField = ({
  selectedLocale,
  onChange,
  onBlur,
  value,
  label,
  errors,
  name,
  type,
  placeholder,
  inputProps,
  className,
  onFileChange,
  onCheckboxChange,
  options,
  labelPlacement,
  onTimeChange,
  disabled,
  helperText,
  clearable,
  multiple,
  disableCloseOnSelect,
  disableClearable,
  onSelectChange,
  isLoading = false,
  onSearch,
  moreLabel,
  asset,
  creatable,
  onCreate,
  autoFocus,
  variant = 'outlined',
  isSmall,
  onInputBlur,
  onInputValueChange,
  maxFileCount,
  onImageDelete,
  onSetValidationErrors,
  onFileDropped,
  freeSolo,
  limit,
  onShowMoreClick,
  richTextProps,
  onFocus,
  leaveDefaultEndAdornment,
  maxDate,
  minDate,
  selectAll,
  onSelectAll,
  timezone,
  isDateControlsVisible,
  timeInputProps,
}: Props) => {
  moment.tz.setDefault(timezone);

  const [autocompleteInput, setAutocompleteInput] = useState<string>('');

  const [isHelpPopoverOpen, setIsHelpPopoverOpen] = useState(false);
  const [helpAnchorEl, setHelpAnchorEl] = useState<any>(null);

  const [isTimePopoverOpen, setIsTimePopoverOpen] = useState(false);

  const intl = useIntl();

  const showErrors = useMemo(() => variant !== 'filled', [variant]);

  const adapterLocale = useMemo(() => {
    if (selectedLocale === 'lt') {
      return lt;
    }

    return enGB;
  }, [selectedLocale]);

  const { setSearchValue }: UseDelayedSearch = useDelayedSearch(
    !!onSearch ? onSearch : () => {},
  );

  useEffect(() => {
    if (type === 'autocomplete') {
      setSearchValue(autocompleteInput);
    }
  }, [autocompleteInput]);

  useEffect(() => {
    if (type === 'richtext') {
      document.addEventListener('focusin', handleRichTextBoxFocusIn);
    }
  }, []);

  useEffect(() => {
    return () =>
      document.removeEventListener('focusin', handleRichTextBoxFocusIn);
  }, []);

  const handleRichTextBoxFocusIn = (event: FocusEvent) => {
    if (
      event.target &&
      // @ts-ignore
      event.target.closest(
        '.tox-tinymce-aux, .moxman-window, .tam-assetmanager-root',
      ) !== null
    ) {
      event.stopImmediatePropagation();
    }
  };

  if (type === 'autocomplete') {
    const getSelectionValue = () => {
      if (Array.isArray(value)) {
        return options?.filter((option) =>
          value.toString().includes(option.value),
        );
      }

      if (multiple) {
        const values = value?.toString().split(',');
        return options?.filter(
          (option) =>
            !!values?.find(
              (singleValue) =>
                singleValue.toString() === option.value?.toString(),
            ),
        );
      }

      return options?.find(
        (option) => option.value.toString() === value?.toString(),
      );
    };

    const getOptions = () => {
      if (!options) {
        return [];
      }

      if (moreLabel) {
        return [...options, { label: moreLabel, value: '' }];
      }

      return options;
    };

    const renderTags = (value: any[], getTagProps: Function) => {
      if (!limit) {
        return;
      }

      const numTags = value.length;

      return (
        <>
          {value.slice(0, limit).map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={index}
              label={option.label}
            />
          ))}

          {numTags > limit && (
            <Chip
              className={styles.showMoreChip}
              label={translate(intl, 'TEXT_FIELD.SHOW_MORE').replace(
                ':count',
                (numTags - limit).toString(),
              )}
              onClick={onShowMoreClick}
            />
          )}
        </>
      );
    };

    return (
      <MuiAutocomplete
        id={name}
        freeSolo={freeSolo}
        className={className}
        multiple={multiple}
        options={getOptions()}
        getOptionLabel={(option: any) => option.label}
        value={getSelectionValue() ?? null}
        disableCloseOnSelect={disableCloseOnSelect}
        disabled={disabled}
        disableClearable={disableClearable}
        inputValue={multiple ? autocompleteInput : undefined}
        renderTags={limit ? renderTags : undefined}
        onChange={(_: any, values: any) => {
          setAutocompleteInput('');
          if (Array.isArray(values)) {
            return onSelectChange?.(
              (values as AutocompleteOptionProps[] | string[]).map((value) => {
                if (typeof value === 'string') {
                  return value;
                }

                return value.value;
              }),
              name,
              freeSolo,
            );
          }

          if (typeof values === 'object') {
            return onSelectChange?.(values?.value || '', name, freeSolo);
          }

          onSelectChange?.(values?.valueOf() || '', name, freeSolo);
        }}
        onBlur={(e) => {
          onBlur?.(e, freeSolo);

          if (freeSolo) {
            setAutocompleteInput('');
          }
        }}
        loading={isLoading}
        renderInput={(params: any) => (
          <>
            <MuiTextField
              {...params}
              label={label}
              placeholder={placeholder}
              helperText={
                showErrors && errors && errors.length > 0 ? errors[0] : ''
              }
              error={errors && errors.length > 0}
              name={name}
              className={cx(styles.textField, styles.textField, className)}
              variant={variant as any}
              size={isSmall ? 'small' : 'medium'}
              onChange={(event) => setAutocompleteInput(event.target.value)}
              InputProps={{
                ...params.InputProps,
                disableUnderline: variant === 'filled' && !errors?.length,
                endAdornment: (
                  <React.Fragment>
                    {leaveDefaultEndAdornment && params.InputProps.endAdornment}
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {creatable && (
                      <Button buttonVariant="text" onClick={() => onCreate?.()}>
                        {translate(intl, 'INPUTS.CREATE')}
                      </Button>
                    )}
                    {selectAll && (
                      <Button
                        buttonVariant="text"
                        onClick={() => onSelectAll?.()}
                      >
                        {translate(intl, 'INPUTS.SELECT_ALL')}
                      </Button>
                    )}
                  </React.Fragment>
                ),
              }}
            />
            {helperText && (
              <Label className={styles.helperLabel} variant={'warning'}>
                <FontAwesomeIcon
                  icon={faCircleExclamation}
                  className={styles.helperLabelIcon}
                />
                <span>{helperText}</span>
              </Label>
            )}
          </>
        )}
        renderOption={(props, option) => (
          <li
            {...props}
            style={{
              textDecoration: option?.isTerminated ? 'line-through' : 'none',
            }}
          >
            {option.label}
          </li>
        )}
      />
    );
  }

  if (type === 'buttonSelect') {
    return (
      <div className={styles.buttonSelectContainer}>
        {options?.map((option, index) => (
          <Button
            key={`button-select-${index}`}
            color={option.value === value ? 'primary' : 'secondary'}
            onClick={() => onSelectChange?.(option.value, name)}
            className={styles.button}
          >
            {option.label}
          </Button>
        ))}
      </div>
    );
  }

  if (type === 'file') {
    return (
      <FileInput
        value={value as File | File[] | string | string[] | undefined}
        name={name}
        helperText={helperText}
        label={label}
        onFileChange={onFileChange}
        maxFileCount={maxFileCount}
        multiple={multiple}
        errors={errors}
        asset={asset}
        onImageDelete={onImageDelete}
        onSetValidationErrors={onSetValidationErrors}
        onFileDropped={onFileDropped}
      />
    );
  }

  if (type === 'currency') {
    return (
      <>
        {helperText && <Alert variant="warning">{helperText}</Alert>}
        <MuiTextField
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          label={label}
          error={errors && errors.length > 0}
          variant={variant as any}
          name={name}
          helperText={
            showErrors && errors && errors.length > 0 ? errors[0] : ''
          }
          className={cx(styles.textField, styles.currencyInput, className)}
          type="number"
          disabled={disabled}
          placeholder={placeholder}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          InputProps={{
            disableUnderline: variant === 'filled' && !errors?.length,
            startAdornment: <InputAdornment position="start">€</InputAdornment>,
          }}
          size={isSmall ? 'small' : 'medium'}
        />
      </>
    );
  }

  if (type === 'staticdate') {
    return (
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={adapterLocale}
      >
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          openTo="day"
          minDate={moment().startOf('year').format('YYYY-MM-DD')}
          maxDate={moment().add(1, 'month').format('YYYY-MM-DD')}
          label={label}
          value={value}
          onChange={(newValue: any) => {
            onTimeChange &&
              onTimeChange(
                name,
                newValue
                  ? moment(newValue.toString()).format('YYYY-MM-DD')
                  : '',
              );
          }}
          InputProps={{
            disableUnderline: variant === 'filled' && !errors?.length,
          }}
          className={styles.staticDateContainer}
          renderInput={(params: any) => (
            <MuiTextField
              {...params}
              className={cx(styles.textField, className)}
              onBlur={onBlur}
              name={name}
              helperText={
                showErrors && errors && errors.length > 0 ? errors[0] : ''
              }
              error={errors && errors.length > 0}
              variant={variant as any}
              size={isSmall ? 'small' : 'medium'}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  if (type === 'datetime') {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          views={['year', 'month', 'day', 'hours', 'minutes']}
          label={label}
          value={value}
          onChange={(newValue: any) => {
            onTimeChange &&
              onTimeChange(
                name,
                newValue
                  ? moment(newValue.toString()).format('YYYY-MM-DD HH:mm')
                  : '',
              );
          }}
          mask="____-__-__ __:__"
          disabled={disabled}
          inputFormat="yyyy-MM-dd HH:mm"
          InputProps={{
            disableUnderline: variant === 'filled' && !errors?.length,
          }}
          renderInput={(params: any) => (
            <MuiTextField
              {...params}
              className={cx(styles.textField, styles.textField, className)}
              onBlur={onBlur}
              name={name}
              helperText={
                showErrors && errors && errors.length > 0 ? errors[0] : ''
              }
              error={errors && errors.length > 0}
              variant={variant as any}
              size={isSmall ? 'small' : 'medium'}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  if (type === 'time') {
    return (
      <MuiTextField
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        label={label}
        error={errors && errors.length > 0}
        variant={variant as any}
        name={name}
        helperText={showErrors && errors && errors.length > 0 ? errors[0] : ''}
        className={cx(styles.textField, className)}
        type={type}
        placeholder={placeholder}
        InputProps={{
          ...inputProps,
        }}
        inputProps={{ ...timeInputProps }}
        disabled={disabled}
        autoFocus={autoFocus}
        size={isSmall ? 'small' : 'medium'}
      />
    );
  }

  if (type === 'timeWithButtons' && onTimeChange) {
    const momentValue = moment(value?.toString(), 'H:m:s A');

    let timeOfDay = momentValue.format('A');
    let hour = Number(momentValue.format('H'));
    let minute = Number(momentValue.format('m'));
    const second = Number(momentValue.format('s'));

    if (hour > 12) {
      hour -= 12;
    }

    const handleHourChange = (changedValue: number) => {
      let newValue = changedValue;

      if (newValue === 11 && timeOfDay === 'PM' && hour === 12) {
        timeOfDay = 'AM';
      } else if (newValue < 0) {
        newValue = 11;
        timeOfDay = timeOfDay === 'AM' ? 'PM' : 'AM';
      } else if (newValue > 11 && newValue !== 13) {
        newValue = 0;
        timeOfDay = timeOfDay === 'AM' ? 'PM' : 'AM';
      }

      onTimeChange(name, `${newValue}:${minute}:${second} ${timeOfDay}`);
    };

    const handleMinuteChange = (changedValue: number) => {
      let newValue = changedValue;

      if (newValue < 0) {
        newValue = 59;

        if (hour > 0) {
          hour--;
        }
      } else if (newValue > 59) {
        newValue = 0;

        if (hour < 11) {
          hour++;
        }
      }

      onTimeChange(name, `${hour}:${newValue}:${second} ${timeOfDay}`);
    };

    const handleSecondChange = (changedValue: number) => {
      let newValue = changedValue;

      if (newValue < 0) {
        newValue = 59;

        if (minute === 0 && hour > 0) {
          minute = 59;
          hour--;
        } else if (minute !== 0) {
          minute--;
        }
      } else if (newValue > 59) {
        newValue = 0;

        if (minute === 59 && hour < 23) {
          minute = 0;
          hour++;
        } else if (minute !== 0) {
          minute++;
        }
      }

      onTimeChange(name, `${hour}:${minute}:${newValue} ${timeOfDay}`);
    };

    const handleTypeOfDayChange = (newTimeOfDay: string) => {
      onTimeChange(name, `${hour}:${minute}:${second} ${newTimeOfDay}`);
    };

    const normalizedValue = (value: number) => {
      return value.toString().padStart(2, '0');
    };

    return (
      <ClickAwayListener onClickAway={() => setIsTimePopoverOpen(false)}>
        <div className={styles.timeWithButtonsWrapper}>
          {isTimePopoverOpen && (
            <div className={styles.timePopoverContent}>
              <div className={styles.timeWithButtonsContainer}>
                <div className={styles.timeControlContainer}>
                  <Button
                    color="secondary"
                    className={styles.controlButton}
                    onClick={() => handleHourChange(hour + 1)}
                  >
                    <FontAwesomeIcon icon={faChevronUp} size="2xs" />
                  </Button>
                  <MuiTextField
                    value={normalizedValue(hour)}
                    onChange={(event) =>
                      handleHourChange(Number(event.target.value))
                    }
                    onBlur={onBlur}
                    onFocus={onFocus}
                    variant={variant as any}
                    name={name}
                    className={cx(
                      styles.textField,
                      styles.timeControlInput,
                      className,
                    )}
                    type="text"
                    disabled={disabled}
                    autoFocus={autoFocus}
                  />
                  <Button
                    color="secondary"
                    className={styles.controlButton}
                    onClick={() => handleHourChange(hour - 1)}
                  >
                    <FontAwesomeIcon icon={faChevronDown} size="2xs" />
                  </Button>
                </div>
                <div className={styles.timeControlContainer}>
                  <Button
                    color="secondary"
                    className={styles.controlButton}
                    onClick={() => handleMinuteChange(minute + 1)}
                  >
                    <FontAwesomeIcon icon={faChevronUp} size="2xs" />
                  </Button>
                  <MuiTextField
                    value={normalizedValue(minute)}
                    onChange={(event) =>
                      handleMinuteChange(Number(event.target.value))
                    }
                    onBlur={onBlur}
                    onFocus={onFocus}
                    variant={variant as any}
                    name={name}
                    className={cx(
                      styles.textField,
                      styles.timeControlInput,
                      className,
                    )}
                    type="text"
                    disabled={disabled}
                    autoFocus={autoFocus}
                  />
                  <Button
                    color="secondary"
                    className={styles.controlButton}
                    onClick={() => handleMinuteChange(minute - 1)}
                  >
                    <FontAwesomeIcon icon={faChevronDown} size="2xs" />
                  </Button>
                </div>
                <div className={styles.timeControlContainer}>
                  <Button
                    color="secondary"
                    className={styles.controlButton}
                    onClick={() => handleSecondChange(second + 1)}
                  >
                    <FontAwesomeIcon icon={faChevronUp} size="2xs" />
                  </Button>
                  <MuiTextField
                    value={normalizedValue(second)}
                    onChange={(event) =>
                      handleSecondChange(Number(event.target.value))
                    }
                    onBlur={onBlur}
                    onFocus={onFocus}
                    variant={variant as any}
                    name={name}
                    className={cx(
                      styles.textField,
                      styles.timeControlInput,
                      className,
                    )}
                    type="text"
                    disabled={disabled}
                    autoFocus={autoFocus}
                  />
                  <Button
                    color="secondary"
                    className={styles.controlButton}
                    onClick={() => handleSecondChange(second - 1)}
                  >
                    <FontAwesomeIcon icon={faChevronDown} size="2xs" />
                  </Button>
                </div>
                <div className={styles.timeControlContainer}>
                  <Button
                    color="secondary"
                    className={styles.controlButton}
                    onClick={() =>
                      handleTypeOfDayChange(timeOfDay === 'AM' ? 'PM' : 'AM')
                    }
                  >
                    <FontAwesomeIcon icon={faChevronUp} size="2xs" />
                  </Button>
                  <MuiTextField
                    value={timeOfDay}
                    onChange={(event) =>
                      handleTypeOfDayChange(event.target.value)
                    }
                    onBlur={onBlur}
                    onFocus={onFocus}
                    variant={variant as any}
                    name={name}
                    className={cx(
                      styles.textField,
                      styles.timeControlInput,
                      className,
                    )}
                    type="text"
                    disabled={disabled}
                    autoFocus={autoFocus}
                  />
                  <Button
                    color="secondary"
                    className={styles.controlButton}
                    onClick={() =>
                      handleTypeOfDayChange(timeOfDay === 'AM' ? 'PM' : 'AM')
                    }
                  >
                    <FontAwesomeIcon icon={faChevronDown} size="2xs" />
                  </Button>
                </div>
              </div>
            </div>
          )}
          <MuiTextField
            value={momentValue.format('hh:mm:ss A')}
            onChange={(event) => onTimeChange(name, event.target.value)}
            onBlur={onBlur}
            onClick={(event) => {
              setIsTimePopoverOpen(true);
            }}
            onFocus={(event) => {
              onFocus?.(event);
            }}
            label={label}
            error={errors && errors.length > 0}
            variant={variant as any}
            name={name}
            helperText={
              showErrors && errors && errors.length > 0 ? errors[0] : ''
            }
            className={cx(styles.textField, className)}
            type={type}
            placeholder={placeholder}
            InputProps={{
              ...inputProps,
            }}
            inputProps={{ ...timeInputProps }}
            disabled={disabled}
            autoFocus={autoFocus}
            size={isSmall ? 'small' : 'medium'}
          />
        </div>
      </ClickAwayListener>
    );
  }

  if (type === 'dateYear') {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          views={['year']}
          label={label}
          value={value}
          onChange={(newValue: any) => {
            onTimeChange &&
              onTimeChange(
                name,
                newValue
                  ? moment(newValue.toString()).format('YYYY-MM-DD')
                  : '',
              );
          }}
          mask="____"
          disabled={disabled}
          inputFormat="yyyy"
          InputProps={{
            disableUnderline: variant === 'filled' && !errors?.length,
          }}
          renderInput={(params: any) => (
            <MuiTextField
              {...params}
              className={cx(styles.textField, styles.textField, className)}
              onBlur={onBlur}
              name={name}
              helperText={
                showErrors && errors && errors.length > 0 ? errors[0] : ''
              }
              error={errors && errors.length > 0}
              variant={variant as any}
              size={isSmall ? 'small' : 'medium'}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  if (type === 'date') {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {isDateControlsVisible && (
          <Button
            className={styles.dateChangeButton}
            color="secondary"
            isDisabled={moment(value?.toString()).isSame(
              moment(minDate),
              'day',
            )}
            onClick={() => {
              onTimeChange &&
                onTimeChange(
                  name,
                  moment(value?.toString())
                    .subtract(1, 'day')
                    .format('YYYY-MM-DD'),
                );
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </Button>
        )}
        <DateTimePicker
          views={['year', 'month', 'day']}
          label={label}
          value={value}
          onChange={(newValue: any) => {
            onTimeChange &&
              onTimeChange(
                name,
                newValue
                  ? moment(newValue.toString()).format('YYYY-MM-DD')
                  : '',
              );
          }}
          mask="____-__-__"
          disabled={disabled}
          inputFormat="yyyy-MM-DD"
          InputProps={{
            disableUnderline: variant === 'filled' && !errors?.length,
          }}
          maxDate={maxDate}
          minDate={minDate}
          renderInput={(params: any) => (
            <MuiTextField
              {...params}
              className={cx(styles.textField, styles.textField, className)}
              onBlur={onBlur}
              name={name}
              helperText={
                showErrors && errors && errors.length > 0 ? errors[0] : ''
              }
              error={errors && errors.length > 0}
              variant={variant as any}
              size={isSmall ? 'small' : 'medium'}
            />
          )}
        />
        {isDateControlsVisible && (
          <Button
            className={styles.dateChangeButton}
            color="secondary"
            isDisabled={moment(value?.toString()).isSame(moment(), 'day')}
            onClick={() => {
              onTimeChange &&
                onTimeChange(
                  name,
                  moment(value?.toString()).add(1, 'day').format('YYYY-MM-DD'),
                );
            }}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </Button>
        )}
      </LocalizationProvider>
    );
  }

  if (type === 'switcher') {
    return (
      <div>
        <FormControlLabel
          className={cx(styles.switcherContainer, className)}
          labelPlacement={labelPlacement}
          control={
            <Switch
              name={name}
              checked={value === '1'}
              onChange={() =>
                onCheckboxChange &&
                onCheckboxChange(name, value === '0' ? '1' : '0')
              }
            />
          }
          disabled={disabled}
          label={label}
        />
        {helperText && (
          <>
            <FontAwesomeIcon
              onClick={(event) => {
                setHelpAnchorEl(event.currentTarget);
                setIsHelpPopoverOpen(true);
              }}
              icon={faCircleExclamation}
              className={styles.helperIcon}
            />
            <Popover
              className={styles.helperPopover}
              open={isHelpPopoverOpen}
              onClose={() => setIsHelpPopoverOpen(false)}
              anchorEl={helpAnchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <div className={styles.popoverContent}>{helperText}</div>
            </Popover>
          </>
        )}
      </div>
    );
  }

  if (type === 'checkbox') {
    return (
      <FormControlLabel
        className={cx(styles.checkboxContainer, className)}
        labelPlacement={labelPlacement}
        control={
          <Checkbox
            checked={value === '1'}
            onChange={() =>
              onCheckboxChange &&
              onCheckboxChange(name, value === '0' ? '1' : '0')
            }
          />
        }
        disabled={disabled}
        label={label}
      />
    );
  }

  if (type === 'select') {
    return (
      <MuiTextField
        value={value}
        select
        onChange={onChange}
        onBlur={onBlur}
        label={label}
        error={errors && errors.length > 0}
        variant={variant as any}
        name={name}
        helperText={showErrors && errors && errors.length > 0 ? errors[0] : ''}
        className={cx(styles.textField, className)}
        type="number"
        placeholder={placeholder}
        size={isSmall ? 'small' : 'medium'}
      >
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        )) ?? []}
      </MuiTextField>
    );
  }

  if (type === 'richtext') {
    return (
      <div
        className={cx(styles.editorContainer, {
          [styles.error]: errors && errors.length,
        })}
      >
        <Editor
          apiKey={process.env.REACT_APP_TINYMCE_KEY}
          disabled={disabled}
          value={value?.toString()}
          onEditorChange={(value) =>
            onInputValueChange && onInputValueChange(name, value)
          }
          onBlur={(event) => {
            event.preventDefault();

            onInputBlur && onInputBlur(name);
          }}
          init={{
            automatic_uploads: true,
            file_picker_types: 'image',
            plugins: 'image | autoresize',
            toolbar:
              'undo redo | formatselect | ' +
              'bold italic backcolor image | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            images_upload_handler: async (blobInfo) => {
              return new Promise(async (resolve, reject) => {
                await assetService.createAsset(
                  blobInfo.blob(),
                  resolve,
                  reject,
                );
              });
            },
            ...richTextProps,
          }}
        />
        {errors &&
          errors.map((error, index) => (
            <div className={styles.editorError} key={`editor-error-${index}`}>
              {error}
            </div>
          ))}
      </div>
    );
  }

  return (
    <Loader isLoading={isLoading} size="2xs">
      <MuiTextField
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        label={label}
        error={errors && errors.length > 0}
        variant={variant as any}
        name={name}
        helperText={showErrors && errors && errors.length > 0 ? errors[0] : ''}
        className={cx(styles.textField, className)}
        type={type}
        placeholder={placeholder}
        InputProps={{
          ...inputProps,
        }}
        multiline={type === 'textarea'}
        rows={5}
        disabled={disabled}
        autoFocus={autoFocus}
        size={isSmall ? 'small' : 'medium'}
      />
      {helperText && (
        <Label className={styles.helperLabel} variant={'warning'}>
          <FontAwesomeIcon
            icon={faCircleExclamation}
            className={styles.helperLabelIcon}
          />
          <span>{helperText}</span>
        </Label>
      )}
    </Loader>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
  timezone: state.user.timezone,
});

export default connect(mapStateToProps)(TextField);
