import React, { ReactNode } from 'react';
import styles from './FormWrapper.module.scss';
import Form from '../Form/Form';
import { HttpError } from '../../config/Axios/axios-instance';
import cx from 'classnames';

export type Props = {
  children?: ReactNode;
  error: HttpError;
  onSubmit: () => void;
  className?: string;
};

const FormWrapper = ({ children, error, onSubmit, className }: Props) => (
  <div className={cx(styles.formContainer, className)}>
    <Form className={styles.form} error={error} onSubmit={onSubmit}>
      {children}
    </Form>
  </div>
);

export default FormWrapper;
