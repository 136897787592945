export const FETCH_FMCSA_REPORTS_LIST_START = 'FETCH_FMCSA_REPORTS_LIST_START';
export const FETCH_FMCSA_REPORTS_LIST_SUCCESS =
  'FETCH_FMCSA_REPORTS_LIST_SUCCESS';
export const FETCH_FMCSA_REPORTS_LIST_FAIL = 'FETCH_FMCSA_REPORTS_LIST_FAIL';

export const SEND_FMCSA_REPORT_START = 'SEND_FMCSA_REPORT_START';
export const SEND_FMCSA_REPORT_SUCCESS = 'SEND_FMCSA_REPORT_SUCCESS';
export const SEND_FMCSA_REPORT_FAIL = 'SEND_FMCSA_REPORT_FAIL';

export const RESET_FMCSA_REPORT_STORE = 'RESET_FMCSA_REPORT_STORE';
export const LOGOUT = 'LOGOUT';

export type FMCSAReportActionTypes =
  | typeof FETCH_FMCSA_REPORTS_LIST_START
  | typeof FETCH_FMCSA_REPORTS_LIST_SUCCESS
  | typeof FETCH_FMCSA_REPORTS_LIST_FAIL
  | typeof SEND_FMCSA_REPORT_START
  | typeof SEND_FMCSA_REPORT_SUCCESS
  | typeof SEND_FMCSA_REPORT_FAIL
  | typeof RESET_FMCSA_REPORT_STORE
  | typeof LOGOUT;
