export enum Roles {
  // eslint-disable-next-line no-unused-vars
  SUPER_ADMIN = 'SUPER_ADMIN',
  // eslint-disable-next-line no-unused-vars
  ADMIN = 'ADMIN',
  // eslint-disable-next-line no-unused-vars
  USER = 'USER',
  // eslint-disable-next-line no-unused-vars
  OWNER = 'OWNER',
  // eslint-disable-next-line no-unused-vars
  DRIVER = 'DRIVER',
  // eslint-disable-next-line no-unused-vars
  MODERATOR = 'MODERATOR',
  // eslint-disable-next-line no-unused-vars
  SUPPORT = 'SUPPORT',
  // eslint-disable-next-line no-unused-vars
  ADMIN_USER = 'ADMIN_USER',
  // eslint-disable-next-line no-unused-vars
}
