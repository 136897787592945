import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Report } from '../../domain/Report';
import { ReportActionTypes } from './actionTypes';

export type ReportStateType = {
  reportsList: ListResults<Report> | null;
  reportsListLoading: boolean;
  reportsListError: HttpError;
  downloadReportLoading: boolean;
  downloadReportError: HttpError;
  downloadedReport: File | null;
  reportDeleteLoading: boolean;
  reportDeleteError: HttpError;
  reportDeleteSuccess: boolean;
  reportsListUpdateNeeded: boolean;
  createReportLoading: boolean;
  createReportError: HttpError;
  createReportSuccess: boolean;
  createdReport: Report | null;
};

export type ReportActionType = ReportStateType & {
  type: ReportActionTypes;
};

export const initialState: ReportStateType = {
  reportsList: null,
  reportsListLoading: true,
  reportsListError: null,
  downloadReportLoading: false,
  downloadReportError: null,
  downloadedReport: null,
  reportDeleteLoading: false,
  reportDeleteError: null,
  reportDeleteSuccess: false,
  reportsListUpdateNeeded: false,
  createReportLoading: false,
  createReportError: null,
  createReportSuccess: false,
  createdReport: null,
};

const fetchReportsListStart = (state: ReportStateType): ReportStateType => ({
  ...state,
  reportsListLoading: true,
});

const fetchReportsListSuccess = (
  state: ReportStateType,
  action: ReportActionType,
): ReportStateType => ({
  ...state,
  reportsListLoading: false,
  reportsList: action.reportsList,
  reportsListUpdateNeeded: false,
  createdReport: null,
});

const fetchReportsListFail = (
  state: ReportStateType,
  action: ReportActionType,
): ReportStateType => ({
  ...state,
  reportsListError: action.reportsListError,
  reportsListLoading: false,
});

const downloadReportStart = (state: ReportStateType): ReportStateType => ({
  ...state,
  downloadReportLoading: true,
});

const downloadReportSuccess = (
  state: ReportStateType,
  action: ReportActionType,
): ReportStateType => {
  return {
    ...state,
    downloadedReport: action.downloadedReport,
    downloadReportLoading: false,
    downloadReportError: null,
    reportsListUpdateNeeded: false,
    createdReport: null,
  };
};

const downloadReportFail = (
  state: ReportStateType,
  action: ReportActionType,
): ReportStateType => ({
  ...state,
  downloadReportError: action.downloadReportError,
  downloadReportLoading: false,
});

const createReportStart = (state: ReportStateType): ReportStateType => ({
  ...state,
  createReportLoading: true,
});

const createReportSuccess = (
  state: ReportStateType,
  action: ReportActionType,
): ReportStateType => {
  return {
    ...state,
    createReportLoading: false,
    createReportError: null,
    reportsListUpdateNeeded: true,
    createReportSuccess: true,
    createdReport: action.createdReport,
  };
};

const createReportFail = (
  state: ReportStateType,
  action: ReportActionType,
): ReportStateType => ({
  ...state,
  createReportError: action.createReportError,
  createReportLoading: false,
  createReportSuccess: false,
});

const reportDeleteStart = (state: ReportStateType): ReportStateType => ({
  ...state,
  reportDeleteLoading: true,
});

const reportDeleteSuccess = (state: ReportStateType): ReportStateType => ({
  ...state,
  reportDeleteLoading: false,
  reportDeleteSuccess: true,
  reportDeleteError: null,
  reportsListUpdateNeeded: true,
});

const reportDeleteError = (
  state: ReportStateType,
  action: ReportActionType,
): ReportStateType => ({
  ...state,
  reportDeleteLoading: false,
  reportDeleteError: action.reportDeleteError,
});

const resetReportStore = (): ReportStateType => ({
  ...initialState,
});

const completelyResetReportStore = (): ReportStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: ReportActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_REPORTS_LIST_START:
      return fetchReportsListStart(state);
    case actionTypes.FETCH_REPORTS_LIST_SUCCESS:
      return fetchReportsListSuccess(state, action);
    case actionTypes.FETCH_REPORTS_LIST_FAIL:
      return fetchReportsListFail(state, action);
    case actionTypes.DOWNLOAD_REPORT_START:
      return downloadReportStart(state);
    case actionTypes.DOWNLOAD_REPORT_SUCCESS:
      return downloadReportSuccess(state, action);
    case actionTypes.DOWNLOAD_REPORT_FAIL:
      return downloadReportFail(state, action);
    case actionTypes.DELETE_REPORT_START:
      return reportDeleteStart(state);
    case actionTypes.DELETE_REPORT_SUCCESS:
      return reportDeleteSuccess(state);
    case actionTypes.DELETE_REPORT_FAIL:
      return reportDeleteError(state, action);
    case actionTypes.CREATE_REPORT_START:
      return createReportStart(state);
    case actionTypes.CREATE_REPORT_SUCCESS:
      return createReportSuccess(state, action);
    case actionTypes.CREATE_REPORT_FAIL:
      return createReportFail(state, action);
    case actionTypes.RESET_REPORT_STORE:
      return resetReportStore();
    case actionTypes.LOGOUT:
      return completelyResetReportStore();
    default:
      return state;
  }
};

export default reducer;
