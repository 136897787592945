import React from 'react';
import { Provider } from 'react-redux';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import authReducer, { AuthStateType } from '../../store/auth/reducer';
import userReducer, { UserStateType } from '../../store/user/reducer';
import languageReducer, {
  LanguageStateType,
} from '../../store/language/reducer';
import translationReducer, {
  TranslationStateType,
} from '../../store/translation/reducer';
import instance from '../Axios/axios-instance';
import * as authService from '../../store/auth/actions';
import driverReducer, { DriverStateType } from '../../store/driver/reducer';
import vehicleReducer, { VehicleStateType } from '../../store/vehicle/reducer';
import eldReducer, { EldStateType } from '../../store/eld/reducer';
import companyReducer, { CompanyStateType } from '../../store/company/reducer';
import userSettingReducer, {
  UserSettingStateType,
} from '../../store/user-setting/reducer';
import driverSettingsReducer, {
  DriverSettingsStateType,
} from '../../store/driver-settings/reducer';
import reportReducer, { ReportStateType } from '../../store/report/reducer';
import fmcsaReducer, {
  FMCSAReportStateType,
} from '../../store/fmcsaReport/reducer';
import certificationReducer, {
  CertificationStateType,
} from '../../store/certification/reducer';
import logGroupReducer, {
  LogGroupStateType,
} from '../../store/log-group/reducer';
import logGroupDayReducer, {
  LogGroupDayStateType,
} from '../../store/log-group-day/reducer';

export type StoreState = {
  auth: AuthStateType;
  user: UserStateType;
  translation: TranslationStateType;
  language: LanguageStateType;
  driver: DriverStateType;
  vehicle: VehicleStateType;
  eld: EldStateType;
  company: CompanyStateType;
  userSetting: UserSettingStateType;
  driverSettings: DriverSettingsStateType;
  report: ReportStateType;
  fmcsaReport: FMCSAReportStateType;
  certification: CertificationStateType;
  logGroup: LogGroupStateType;
  logGroupDay: LogGroupDayStateType;
};

type Props = {
  children?: any;
};

const rootReducer = combineReducers<StoreState>({
  auth: authReducer,
  user: userReducer,
  translation: translationReducer,
  language: languageReducer,
  driver: driverReducer,
  vehicle: vehicleReducer,
  eld: eldReducer,
  company: companyReducer,
  userSetting: userSettingReducer,
  driverSettings: driverSettingsReducer,
  report: reportReducer,
  fmcsaReport: fmcsaReducer,
  certification: certificationReducer,
  logGroup: logGroupReducer,
  logGroupDay: logGroupDayReducer,
});

export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store: Store<any, any> = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

const { dispatch } = store;
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.config.url !== '/token'
    ) {
      dispatch(authService.logout());
    }

    return Promise.reject(error);
  },
);

const StoreProvider = ({ children }: Props) => (
  <Provider store={store}>{children}</Provider>
);

export default StoreProvider;
