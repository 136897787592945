import React, { ReactNode } from 'react';
import styles from './CurrentAccount.module.scss';

type Props = {
  title: string;
  subTitle: string;
  children: ReactNode;
  onClick: () => void;
  companyName?: string;
};

export const CurrentAccount = ({
  title,
  subTitle,
  children,
  onClick,
  companyName,
}: Props) => {
  return (
    <div className={styles.currentAccountContainer} onClick={onClick}>
      <div className={styles.content}>
        {!companyName && <div className={styles.title}>{title}</div>}
        <div className={styles.subTitle}>{subTitle}</div>
        <div className={styles.companyName}>{companyName}</div>
      </div>
      {children}
    </div>
  );
};

export default CurrentAccount;
