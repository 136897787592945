import * as actionTypes from './actionTypes';
import { DriverSettingsActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { DriverSettings } from '../../domain/DriverSettings';

export type DriverSettingsStateType = {
  updateDriverSettingsLoading: boolean;
  updateDriverSettingsError: HttpError;
  driverSettings: DriverSettings | null;
};

export type DriverSettingsActionType = DriverSettingsStateType & {
  type: DriverSettingsActionTypes;
};

export const initialState: DriverSettingsStateType = {
  updateDriverSettingsLoading: false,
  driverSettings: null,
  updateDriverSettingsError: null,
};

const updateDriverSettingsStart = (
  state: DriverSettingsStateType,
): DriverSettingsStateType => ({
  ...state,
  updateDriverSettingsLoading: true,
});

const updateDriverSettingsSuccess = (
  state: DriverSettingsStateType,
  action: DriverSettingsActionType,
): DriverSettingsStateType => ({
  ...state,
  updateDriverSettingsLoading: false,
  updateDriverSettingsError: null,
  driverSettings: action.driverSettings,
});

const updateDriverSettingsFail = (
  state: DriverSettingsStateType,
  action: DriverSettingsActionType,
): DriverSettingsStateType => ({
  ...state,
  updateDriverSettingsLoading: false,
  updateDriverSettingsError: action.updateDriverSettingsError,
});

const resetStore = () => ({
  ...initialState,
});

const completelyResetDriverSettingsStore = () => ({
  ...initialState,
});

const reducer = (state = initialState, action: DriverSettingsActionType) => {
  switch (action.type) {
    case actionTypes.UPDATE_DRIVER_SETTINGS_START:
      return updateDriverSettingsStart(state);
    case actionTypes.UPDATE_DRIVER_SETTINGS_SUCCESS:
      return updateDriverSettingsSuccess(state, action);
    case actionTypes.UPDATE_DRIVER_SETTINGS_FAIL:
      return updateDriverSettingsFail(state, action);
    case actionTypes.RESET_DRIVER_SETTINGS_STORE:
      return resetStore();
    case actionTypes.LOGOUT:
      return completelyResetDriverSettingsStore();
    default:
      return state;
  }
};

export default reducer;
