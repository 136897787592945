import axios from 'axios';

export type JwtToken = {
  email: string;
  iat: number;
  exp: number;
};

export type HttpError =
  | null
  | string
  | Array<{ [key: string]: string }>
  | Array<{ field: string; message: string }>;

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  const companyId = localStorage.getItem('selectedCompany');

  if (token && config.headers) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  if (companyId && config.headers) {
    config.headers['Company'] = companyId;
  }

  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 413) {
      error.response['data'] = {
        statusCode: 413,
        message: 'File too large (max 2MB)',
        error: 'Payload Too Large',
      };
    }

    return Promise.reject(error);
  },
);

export default instance;
