import * as actionTypes from './actionTypes';
import { EldActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Eld } from '../../domain/Eld';
import { EldHistory } from '../../domain/EldHistory';

export type EldStateType = {
  eldsList: ListResults<Eld> | null;
  eldsListLoading: boolean;
  eldsListError: HttpError;
  eldsListUpdateNeeded: boolean;
  allElds: Eld[];
  allEldsLoading: boolean;
  allEldsError: HttpError;
  eldHistory: EldHistory[] | null;
  eldHistoryLoading: boolean;
  eldHistoryError: HttpError;
};

export type EldActionType = EldStateType & {
  type: EldActionTypes;
};

export const initialState: EldStateType = {
  eldsList: null,
  eldsListLoading: true,
  eldsListError: null,
  eldsListUpdateNeeded: false,
  allElds: [],
  allEldsLoading: false,
  allEldsError: null,
  eldHistory: null,
  eldHistoryLoading: true,
  eldHistoryError: null,
};

const fetchEldsListStart = (state: EldStateType): EldStateType => ({
  ...state,
  eldsListLoading: true,
});

const fetchEldsListSuccess = (
  state: EldStateType,
  action: EldActionType,
): EldStateType => ({
  ...state,
  eldsListLoading: false,
  eldsList: action.eldsList,
  eldsListUpdateNeeded: false,
});

const fetchEldsListFail = (
  state: EldStateType,
  action: EldActionType,
): EldStateType => ({
  ...state,
  eldsListError: action.eldsListError,
  eldsListLoading: false,
});

const fetchAllEldsStart = (state: EldStateType): EldStateType => ({
  ...state,
  allEldsLoading: true,
});

const fetchAllEldsSuccess = (
  state: EldStateType,
  action: EldActionType,
): EldStateType => ({
  ...state,
  allEldsLoading: false,
  allElds: action.allElds,
  allEldsError: null,
});

const fetchAllEldsFail = (
  state: EldStateType,
  action: EldActionType,
): EldStateType => ({
  ...state,
  allEldsError: action.allEldsError,
  allEldsLoading: false,
});

const fetchEldHistoryStart = (state: EldStateType): EldStateType => ({
  ...state,
  eldHistoryLoading: true,
});

const fetchEldHistorySuccess = (
  state: EldStateType,
  action: EldActionType,
): EldStateType => ({
  ...state,
  eldHistoryLoading: false,
  eldHistory: action.eldHistory,
});

const fetchEldHistoryFail = (
  state: EldStateType,
  action: EldActionType,
): EldStateType => ({
  ...state,
  eldHistoryError: action.eldHistoryError,
  eldHistoryLoading: false,
});

const resetEldHistoryStore = (state: EldStateType): EldStateType => ({
  ...state,
  eldHistoryLoading: false,
  eldHistory: null,
  eldHistoryError: null,
});

const resetEldStore = (): EldStateType => ({
  ...initialState,
});

const completelyResetEldStore = (): EldStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: EldActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ELDS_LIST_START:
      return fetchEldsListStart(state);
    case actionTypes.FETCH_ELDS_LIST_SUCCESS:
      return fetchEldsListSuccess(state, action);
    case actionTypes.FETCH_ELDS_LIST_FAIL:
      return fetchEldsListFail(state, action);
    case actionTypes.FETCH_ALL_ELDS_START:
      return fetchAllEldsStart(state);
    case actionTypes.FETCH_ALL_ELDS_SUCCESS:
      return fetchAllEldsSuccess(state, action);
    case actionTypes.FETCH_ALL_ELDS_FAIL:
      return fetchAllEldsFail(state, action);
    case actionTypes.FETCH_ELD_HISTORY_START:
      return fetchEldHistoryStart(state);
    case actionTypes.FETCH_ELD_HISTORY_SUCCESS:
      return fetchEldHistorySuccess(state, action);
    case actionTypes.FETCH_ELD_HISTORY_FAIL:
      return fetchEldHistoryFail(state, action);
    case actionTypes.RESET_ELD_HISTORY_STORE:
      return resetEldHistoryStore(state);
    case actionTypes.RESET_ELD_STORE:
      return resetEldStore();
    case actionTypes.LOGOUT:
      return completelyResetEldStore();
    default:
      return state;
  }
};

export default reducer;
