export const FETCH_CERTIFICATION_SIGNATURE_START =
  'FETCH_CERTIFICATION_SIGNATURE_START';
export const FETCH_CERTIFICATION_SIGNATURE_SUCCESS =
  'FETCH_CERTIFICATION_SIGNATURE_SUCCESS';
export const FETCH_CERTIFICATION_SIGNATURE_FAIL =
  'FETCH_CERTIFICATION_SIGNATURE_FAIL';

export const RESET_CERTIFICATION_STORE = 'RESET_CERTIFICATION_STORE';

export const LOGOUT = 'LOGOUT';

export type CertificationActionTypes =
  | typeof FETCH_CERTIFICATION_SIGNATURE_START
  | typeof FETCH_CERTIFICATION_SIGNATURE_SUCCESS
  | typeof FETCH_CERTIFICATION_SIGNATURE_FAIL
  | typeof RESET_CERTIFICATION_STORE
  | typeof LOGOUT;
