export const FETCH_DRIVERS_LIST_START = 'FETCH_DRIVERS_LIST_START';
export const FETCH_DRIVERS_LIST_SUCCESS = 'FETCH_DRIVERS_LIST_SUCCESS';
export const FETCH_DRIVERS_LIST_FAIL = 'FETCH_DRIVERS_LIST_FAIL';

export const FETCH_DRIVER_START = 'FETCH_DRIVER_START';
export const FETCH_DRIVER_SUCCESS = 'FETCH_DRIVER_SUCCESS';
export const FETCH_DRIVER_FAIL = 'FETCH_DRIVER_FAIL';

export const CREATE_DRIVER_START = 'CREATE_DRIVER_START';
export const CREATE_DRIVER_SUCCESS = 'CREATE_DRIVER_SUCCESS';
export const CREATE_DRIVER_FAIL = 'CREATE_DRIVER_FAIL';

export const UPDATE_DRIVER_START = 'UPDATE_DRIVER_START';
export const UPDATE_DRIVER_SUCCESS = 'UPDATE_DRIVER_SUCCESS';
export const UPDATE_DRIVER_FAIL = 'UPDATE_DRIVER_FAIL';

export const DELETE_DRIVER_START = 'DELETE_DRIVER_START';
export const DELETE_DRIVER_SUCCESS = 'DELETE_DRIVER_SUCCESS';
export const DELETE_DRIVER_FAIL = 'DELETE_DRIVER_FAIL';

export const FETCH_ALL_DRIVERS_START = 'FETCH_ALL_DRIVERS_START';
export const FETCH_ALL_DRIVERS_SUCCESS = 'FETCH_ALL_DRIVERS_SUCCESS';
export const FETCH_ALL_DRIVERS_FAIL = 'FETCH_ALL_DRIVERS_FAIL';

export const FETCH_DRIVER_LOGS_START = 'FETCH_DRIVER_LOGS_START';
export const FETCH_DRIVER_LOGS_SUCCESS = 'FETCH_DRIVER_LOGS_SUCCESS';
export const FETCH_DRIVER_LOGS_FAIL = 'FETCH_DRIVER_LOGS_FAIL';

export const UPDATE_DRIVER_CREDENTIALS_START =
  'UPDATE_DRIVER_CREDENTIALS_START';
export const UPDATE_DRIVER_CREDENTIALS_SUCCESS =
  'UPDATE_DRIVER_CREDENTIALS_SUCCESS';
export const UPDATE_DRIVER_CREDENTIALS_FAIL = 'UPDATE_DRIVER_CREDENTIALS_FAIL';

export const CHANGE_DRIVER_STATUS_START = 'CHANGE_DRIVER_STATUS_START';
export const CHANGE_DRIVER_STATUS_SUCCESS = 'CHANGE_DRIVER_STATUS_SUCCESS';
export const CHANGE_DRIVER_STATUS_FAIL = 'CHANGE_DRIVER_STATUS_FAIL';

export const RESET_DRIVER_STORE = 'RESET_DRIVER_STORE';
export const RESET_CREATED_DRIVER = 'RESET_CREATED_DRIVER';
export const RESET_UPDATED_DRIVER = 'RESET_UPDATED_DRIVER';
export const RESET_UPDATED_DRIVER_CREDENTIALS =
  'RESET_UPDATED_DRIVER_CREDENTIALS';

export const RESET_DRIVER_LOGS = 'RESET_DRIVER_LOGS';
export const LOGOUT = 'LOGOUT';

export type DriverActionTypes =
  | typeof FETCH_DRIVERS_LIST_START
  | typeof FETCH_DRIVERS_LIST_SUCCESS
  | typeof FETCH_DRIVERS_LIST_FAIL
  | typeof FETCH_ALL_DRIVERS_START
  | typeof FETCH_ALL_DRIVERS_SUCCESS
  | typeof FETCH_ALL_DRIVERS_FAIL
  | typeof FETCH_DRIVER_START
  | typeof FETCH_DRIVER_SUCCESS
  | typeof FETCH_DRIVER_FAIL
  | typeof FETCH_DRIVER_LOGS_START
  | typeof FETCH_DRIVER_LOGS_SUCCESS
  | typeof FETCH_DRIVER_LOGS_FAIL
  | typeof CREATE_DRIVER_START
  | typeof CREATE_DRIVER_SUCCESS
  | typeof CREATE_DRIVER_FAIL
  | typeof UPDATE_DRIVER_START
  | typeof UPDATE_DRIVER_SUCCESS
  | typeof UPDATE_DRIVER_FAIL
  | typeof DELETE_DRIVER_START
  | typeof DELETE_DRIVER_SUCCESS
  | typeof DELETE_DRIVER_FAIL
  | typeof UPDATE_DRIVER_CREDENTIALS_START
  | typeof UPDATE_DRIVER_CREDENTIALS_SUCCESS
  | typeof UPDATE_DRIVER_CREDENTIALS_FAIL
  | typeof CHANGE_DRIVER_STATUS_START
  | typeof CHANGE_DRIVER_STATUS_SUCCESS
  | typeof CHANGE_DRIVER_STATUS_FAIL
  | typeof RESET_DRIVER_STORE
  | typeof RESET_CREATED_DRIVER
  | typeof RESET_UPDATED_DRIVER
  | typeof RESET_UPDATED_DRIVER_CREDENTIALS
  | typeof RESET_DRIVER_LOGS
  | typeof LOGOUT;
