export const FETCH_REPORTS_LIST_START = 'FETCH_REPORTS_LIST_START';
export const FETCH_REPORTS_LIST_SUCCESS = 'FETCH_REPORTS_LIST_SUCCESS';
export const FETCH_REPORTS_LIST_FAIL = 'FETCH_REPORTS_LIST_FAIL';

export const DOWNLOAD_REPORT_START = 'DOWNLOAD_REPORT_START';
export const DOWNLOAD_REPORT_SUCCESS = 'DOWNLOAD_REPORT_SUCCESS';
export const DOWNLOAD_REPORT_FAIL = 'DOWNLOAD_REPORT_FAIL';

export const CREATE_REPORT_START = 'CREATE_REPORT_START';
export const CREATE_REPORT_SUCCESS = 'CREATE_REPORT_SUCCESS';
export const CREATE_REPORT_FAIL = 'CREATE_REPORT_FAIL';

export const DELETE_REPORT_START = 'DELETE_REPORT_START';
export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS';
export const DELETE_REPORT_FAIL = 'DELETE_REPORT_FAIL';

export const RESET_REPORT_STORE = 'RESET_REPORT_STORE';
export const LOGOUT = 'LOGOUT';

export type ReportActionTypes =
  | typeof FETCH_REPORTS_LIST_START
  | typeof FETCH_REPORTS_LIST_SUCCESS
  | typeof FETCH_REPORTS_LIST_FAIL
  | typeof DOWNLOAD_REPORT_START
  | typeof DOWNLOAD_REPORT_SUCCESS
  | typeof DOWNLOAD_REPORT_FAIL
  | typeof CREATE_REPORT_START
  | typeof CREATE_REPORT_SUCCESS
  | typeof CREATE_REPORT_FAIL
  | typeof DELETE_REPORT_START
  | typeof DELETE_REPORT_SUCCESS
  | typeof DELETE_REPORT_FAIL
  | typeof RESET_REPORT_STORE
  | typeof LOGOUT;
