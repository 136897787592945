import React from 'react';
import styles from './Navigation.module.scss';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { IconButton } from '@mui/material';
import {
  faBars,
  faSignOutAlt,
  faTimes,
  faCog,
  faKey,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as authService from '../../store/auth/service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { Roles } from '../../domain/Role';
import { User } from '../../domain/User';
import { useNavigate } from 'react-router';
import { routes } from '../../config/Router/routes';

export type Props = {
  onLogout: () => void;
  onDrawerClick: () => void;
  isMobileMenuOpen: boolean;
  currentUser: User | null;
  selectedCompany: number | null;
};

const MOBILE_BREAK_POINT = 900;

export const Navigation = ({
  onLogout,
  onDrawerClick,
  isMobileMenuOpen,
  currentUser,
  selectedCompany,
}: Props) => {
  const { width } = useWindowSize();
  const navigate = useNavigate();

  return (
    <>
      <header className={styles.navigationContainer}>
        <div className={styles.iconItems}>
          {width && width < MOBILE_BREAK_POINT && (
            <IconButton onClick={() => onDrawerClick()}>
              <FontAwesomeIcon
                icon={(isMobileMenuOpen ? faTimes : faBars) as IconProp}
                fixedWidth
                size="sm"
              />
            </IconButton>
          )}
        </div>
        <div className={styles.rightContainer}>
          {currentUser?.role === Roles.OWNER && selectedCompany && (
            <div className={styles.iconItems}>
              <IconButton
                onClick={() =>
                  navigate(
                    routes.companies.ownerEdit.replace(
                      ':id',
                      selectedCompany.toString(),
                    ),
                  )
                }
              >
                <FontAwesomeIcon
                  icon={faCog as IconProp}
                  fixedWidth
                  size="sm"
                />
              </IconButton>
            </div>
          )}
          <div className={styles.iconItems}>
            <IconButton onClick={() => navigate(routes.changePassword)}>
              <FontAwesomeIcon icon={faKey as IconProp} fixedWidth size="sm" />
            </IconButton>
            <IconButton onClick={() => onLogout()}>
              <FontAwesomeIcon
                icon={faSignOutAlt as IconProp}
                fixedWidth
                size="sm"
              />
            </IconButton>
          </div>
        </div>
      </header>
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  currentUser: state.user.currentUser,
  selectedCompany: state.company.selectedCompany,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLogout: () => dispatch(authService.logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
