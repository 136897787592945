import * as actionTypes from './actionTypes';
import { DriverActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Driver } from '../../domain/Driver';

export type DriverStateType = {
  driversList: ListResults<Driver> | null;
  driversListLoading: boolean;
  driversListError: HttpError;
  driversListUpdateNeeded: boolean;
  driverCreateLoading: boolean;
  driverCreateError: HttpError;
  driverCreateSuccess: boolean;
  createdDriver: Driver | null;
  driverUpdateLoading: boolean;
  driverUpdateError: HttpError;
  driverUpdateSuccess: boolean;
  driver: Driver | null;
  driverLoading: boolean;
  driverError: HttpError;
  driverDeleteLoading: boolean;
  driverDeleteError: HttpError;
  driverDeleteSuccess: boolean;
  allDrivers: Driver[];
  allDriversLoading: boolean;
  allDriversError: HttpError;
  driverUpdateCredentialsLoading: boolean;
  driverUpdateCredentialsSuccess: boolean;
  driverUpdateCredentialsError: HttpError;
  changeDriverStatusLoading: boolean;
  changeDriverStatusError: HttpError;
  changeDriverStatusSuccess: boolean;
  driverLogs: Driver | null;
  driverLogsLoading: boolean;
  driverLogsError: HttpError;
};

export type DriverActionType = DriverStateType & {
  type: DriverActionTypes;
};

export const initialState: DriverStateType = {
  driversList: null,
  driversListLoading: true,
  driversListError: null,
  driversListUpdateNeeded: false,
  driverCreateLoading: false,
  driverCreateError: null,
  driverCreateSuccess: false,
  createdDriver: null,
  driverUpdateLoading: false,
  driverUpdateError: null,
  driverUpdateSuccess: false,
  driver: null,
  driverLoading: false,
  driverError: null,
  driverDeleteLoading: false,
  driverDeleteError: null,
  driverDeleteSuccess: false,
  allDrivers: [],
  allDriversLoading: false,
  allDriversError: null,
  driverUpdateCredentialsLoading: false,
  driverUpdateCredentialsSuccess: false,
  driverUpdateCredentialsError: null,
  changeDriverStatusLoading: false,
  changeDriverStatusError: null,
  changeDriverStatusSuccess: false,
  driverLogs: null,
  driverLogsLoading: true,
  driverLogsError: null,
};

const fetchDriversListStart = (state: DriverStateType): DriverStateType => ({
  ...state,
  driversListLoading: true,
});

const fetchDriversListSuccess = (
  state: DriverStateType,
  action: DriverActionType,
): DriverStateType => ({
  ...state,
  driversListLoading: false,
  driversList: action.driversList,
  driversListUpdateNeeded: false,
  driverCreateSuccess: false,
});

const fetchDriversListFail = (
  state: DriverStateType,
  action: DriverActionType,
): DriverStateType => ({
  ...state,
  driversListError: action.driversListError,
  driversListLoading: false,
});

const fetchDriverStart = (state: DriverStateType): DriverStateType => ({
  ...state,
  driverLoading: true,
});

const fetchDriverSuccess = (
  state: DriverStateType,
  action: DriverActionType,
): DriverStateType => ({
  ...state,
  driver: action.driver,
  driverLoading: false,
});

const fetchDriverFail = (
  state: DriverStateType,
  action: DriverActionType,
): DriverStateType => ({
  ...state,
  driversListError: action.driversListError,
  driverLoading: false,
});

const fetchDriverLogsStart = (state: DriverStateType): DriverStateType => ({
  ...state,
  driverLogsLoading: true,
});

const fetchDriverLogsSuccess = (
  state: DriverStateType,
  action: DriverActionType,
): DriverStateType => ({
  ...state,
  driverLogsLoading: false,
  driverLogs: action.driverLogs,
});

const fetchDriverLogsFail = (
  state: DriverStateType,
  action: DriverActionType,
): DriverStateType => ({
  ...state,
  driverLogsError: action.driverLogsError,
  driverLogsLoading: false,
});

const createDriverStart = (state: DriverStateType): DriverStateType => ({
  ...state,
  driverCreateLoading: true,
});

const createDriverSuccess = (
  state: DriverStateType,
  action: DriverActionType,
): DriverStateType => ({
  ...state,
  driverCreateLoading: false,
  driverCreateSuccess: true,
  createdDriver: action.createdDriver,
  driversListUpdateNeeded: true,
});

const createDriverFail = (
  state: DriverStateType,
  action: DriverActionType,
): DriverStateType => ({
  ...state,
  driverCreateLoading: false,
  driverCreateError: action.driverCreateError,
});

const updateEmplyoeeStart = (state: DriverStateType): DriverStateType => ({
  ...state,
  driverUpdateLoading: true,
});

const updateDriverSuccess = (state: DriverStateType): DriverStateType => ({
  ...state,
  driverUpdateLoading: false,
  driverUpdateSuccess: true,
  driversListUpdateNeeded: true,
  driverUpdateError: null,
});

const updateDriverFail = (
  state: DriverStateType,
  action: DriverActionType,
): DriverStateType => ({
  ...state,
  driverUpdateLoading: false,
  driverUpdateError: action.driverUpdateError,
});

const driverDeleteStart = (state: DriverStateType): DriverStateType => ({
  ...state,
  driverDeleteLoading: true,
});

const driverDeleteSuccess = (state: DriverStateType): DriverStateType => ({
  ...state,
  driverDeleteLoading: false,
  driverDeleteSuccess: true,
  driverDeleteError: null,
  driversListUpdateNeeded: true,
});

const driverDeleteError = (
  state: DriverStateType,
  action: DriverActionType,
): DriverStateType => ({
  ...state,
  driverDeleteLoading: false,
  driverDeleteError: action.driverDeleteError,
});

const resetCreatedDriver = (state: DriverStateType): DriverStateType => ({
  ...state,
  createdDriver: null,
  driverCreateLoading: false,
  driverCreateSuccess: false,
  driverCreateError: null,
  driversListUpdateNeeded: false,
});

const resetUpdatedDriver = (state: DriverStateType): DriverStateType => ({
  ...state,
  driver: null,
  driverLoading: false,
  driverError: null,
  driverUpdateLoading: false,
  driverUpdateSuccess: false,
  driverUpdateCredentialsSuccess: false,
  driverUpdateError: null,
  driversListUpdateNeeded: false,
});

const fetchAllDriversStart = (state: DriverStateType): DriverStateType => ({
  ...state,
  allDriversLoading: true,
});

const fetchAllDriversSuccess = (
  state: DriverStateType,
  action: DriverActionType,
): DriverStateType => ({
  ...state,
  allDriversLoading: false,
  allDrivers: action.allDrivers,
  allDriversError: null,
});

const fetchAllDriversFail = (
  state: DriverStateType,
  action: DriverActionType,
): DriverStateType => ({
  ...state,
  allDriversError: action.allDriversError,
  allDriversLoading: false,
});

const updateDriverCredentialsStart = (
  state: DriverStateType,
): DriverStateType => ({
  ...state,
  driverUpdateCredentialsLoading: true,
});

const updateDriverCredentialsSuccess = (
  state: DriverStateType,
  action: DriverActionType,
): DriverStateType => ({
  ...state,
  driverUpdateCredentialsLoading: false,
  driverUpdateCredentialsError: null,
  driverUpdateCredentialsSuccess: action.driverUpdateCredentialsSuccess,
  driver: action.driver,
});

const updateDriverCredentialsFail = (
  state: DriverStateType,
  action: DriverActionType,
): DriverStateType => ({
  ...state,
  driverUpdateCredentialsLoading: false,
  driverUpdateCredentialsError: action.driverUpdateCredentialsError,
});

const changeDriverStatusStart = (state: DriverStateType): DriverStateType => ({
  ...state,
  changeDriverStatusLoading: true,
});

const changeDriverStatusSuccess = (
  state: DriverStateType,
): DriverStateType => ({
  ...state,
  changeDriverStatusLoading: false,
  changeDriverStatusError: null,
  changeDriverStatusSuccess: true,
  driversListUpdateNeeded: true,
});

const changeDriverStatusFail = (
  state: DriverStateType,
  action: DriverActionType,
): DriverStateType => ({
  ...state,
  changeDriverStatusLoading: false,
  changeDriverStatusError: action.changeDriverStatusError,
});

const resetUpdatedDriverCredentials = (
  state: DriverStateType,
): DriverStateType => ({
  ...state,
  driverUpdateCredentialsLoading: false,
  driverUpdateCredentialsSuccess: false,
  driverUpdateCredentialsError: null,
});

const resetDriverLogs = (state: DriverStateType): DriverStateType => ({
  ...state,
  driverLogs: null,
  driverLogsLoading: true,
  driverLogsError: null,
});

const resetDriverStore = (): DriverStateType => ({
  ...initialState,
});

const completelyResetDriverStore = (): DriverStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: DriverActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_DRIVERS_LIST_START:
      return fetchDriversListStart(state);
    case actionTypes.FETCH_DRIVERS_LIST_SUCCESS:
      return fetchDriversListSuccess(state, action);
    case actionTypes.FETCH_DRIVERS_LIST_FAIL:
      return fetchDriversListFail(state, action);
    case actionTypes.FETCH_DRIVER_START:
      return fetchDriverStart(state);
    case actionTypes.FETCH_DRIVER_SUCCESS:
      return fetchDriverSuccess(state, action);
    case actionTypes.FETCH_DRIVER_FAIL:
      return fetchDriverFail(state, action);
    case actionTypes.CREATE_DRIVER_START:
      return createDriverStart(state);
    case actionTypes.CREATE_DRIVER_SUCCESS:
      return createDriverSuccess(state, action);
    case actionTypes.CREATE_DRIVER_FAIL:
      return createDriverFail(state, action);
    case actionTypes.UPDATE_DRIVER_START:
      return updateEmplyoeeStart(state);
    case actionTypes.UPDATE_DRIVER_SUCCESS:
      return updateDriverSuccess(state);
    case actionTypes.UPDATE_DRIVER_FAIL:
      return updateDriverFail(state, action);
    case actionTypes.DELETE_DRIVER_START:
      return driverDeleteStart(state);
    case actionTypes.DELETE_DRIVER_SUCCESS:
      return driverDeleteSuccess(state);
    case actionTypes.DELETE_DRIVER_FAIL:
      return driverDeleteError(state, action);
    case actionTypes.FETCH_ALL_DRIVERS_START:
      return fetchAllDriversStart(state);
    case actionTypes.FETCH_ALL_DRIVERS_SUCCESS:
      return fetchAllDriversSuccess(state, action);
    case actionTypes.FETCH_ALL_DRIVERS_FAIL:
      return fetchAllDriversFail(state, action);
    case actionTypes.FETCH_DRIVER_LOGS_START:
      return fetchDriverLogsStart(state);
    case actionTypes.FETCH_DRIVER_LOGS_SUCCESS:
      return fetchDriverLogsSuccess(state, action);
    case actionTypes.FETCH_DRIVER_LOGS_FAIL:
      return fetchDriverLogsFail(state, action);
    case actionTypes.UPDATE_DRIVER_CREDENTIALS_START:
      return updateDriverCredentialsStart(state);
    case actionTypes.UPDATE_DRIVER_CREDENTIALS_SUCCESS:
      return updateDriverCredentialsSuccess(state, action);
    case actionTypes.UPDATE_DRIVER_CREDENTIALS_FAIL:
      return updateDriverCredentialsFail(state, action);
    case actionTypes.CHANGE_DRIVER_STATUS_START:
      return changeDriverStatusStart(state);
    case actionTypes.CHANGE_DRIVER_STATUS_SUCCESS:
      return changeDriverStatusSuccess(state);
    case actionTypes.CHANGE_DRIVER_STATUS_FAIL:
      return changeDriverStatusFail(state, action);
    case actionTypes.RESET_UPDATED_DRIVER_CREDENTIALS:
      return resetUpdatedDriverCredentials(state);
    case actionTypes.RESET_DRIVER_STORE:
      return resetDriverStore();
    case actionTypes.RESET_UPDATED_DRIVER:
      return resetUpdatedDriver(state);
    case actionTypes.RESET_CREATED_DRIVER:
      return resetCreatedDriver(state);
    case actionTypes.RESET_DRIVER_LOGS:
      return resetDriverLogs(state);
    case actionTypes.LOGOUT:
      return completelyResetDriverStore();
    default:
      return state;
  }
};

export default reducer;
