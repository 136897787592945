export const EDIT_LOG_GROUPS_START = 'EDIT_LOG_GROUPS_START';
export const EDIT_LOG_GROUPS_SUCCESS = 'EDIT_LOG_GROUPS_SUCCESS';
export const EDIT_LOG_GROUPS_FAIL = 'EDIT_LOG_GROUPS_FAIL';
export const EDIT_LOG_GROUPS_RESET = 'EDIT_LOG_GROUPS_RESET';

export const DELETE_LOG_GROUPS_START = 'DELETE_LOG_GROUPS_START';
export const DELETE_LOG_GROUPS_SUCCESS = 'DELETE_LOG_GROUPS_SUCCESS';
export const DELETE_LOG_GROUPS_FAIL = 'DELETE_LOG_GROUPS_FAIL';

export const FETCH_LOG_GROUPS_START = 'FETCH_LOG_GROUPS_START';
export const FETCH_LOG_GROUPS_SUCCESS = 'FETCH_LOG_GROUPS_SUCCESS';
export const FETCH_LOG_GROUPS_FAIL = 'FETCH_LOG_GROUPS_FAIL';

export const FETCH_UNIDENTIFIED_DRIVINGS_START =
  'FETCH_UNIDENTIFIED_DRIVINGS_START';
export const FETCH_UNIDENTIFIED_DRIVINGS_SUCCESS =
  'FETCH_UNIDENTIFIED_DRIVINGS_SUCCESS';
export const FETCH_UNIDENTIFIED_DRIVINGS_FAIL =
  'FETCH_UNIDENTIFIED_DRIVINGS_FAIL';

export const ASSIGN_LOG_START = 'ASSIGN_LOG_START';
export const ASSIGN_LOG_SUCCESS = 'ASSIGN_LOG_SUCCESS';
export const ASSIGN_LOG_FAIL = 'ASSIGN_LOG_FAIL';
export const ASSIGN_LOG_RESET = 'ASSIGN_LOG_RESET';

export const LOGOUT = 'LOGOUT';

export type LogGroupActionTypes =
  | typeof EDIT_LOG_GROUPS_START
  | typeof EDIT_LOG_GROUPS_SUCCESS
  | typeof EDIT_LOG_GROUPS_FAIL
  | typeof EDIT_LOG_GROUPS_RESET
  | typeof DELETE_LOG_GROUPS_START
  | typeof DELETE_LOG_GROUPS_SUCCESS
  | typeof DELETE_LOG_GROUPS_FAIL
  | typeof FETCH_LOG_GROUPS_START
  | typeof FETCH_LOG_GROUPS_SUCCESS
  | typeof FETCH_LOG_GROUPS_FAIL
  | typeof FETCH_UNIDENTIFIED_DRIVINGS_START
  | typeof FETCH_UNIDENTIFIED_DRIVINGS_SUCCESS
  | typeof FETCH_UNIDENTIFIED_DRIVINGS_FAIL
  | typeof ASSIGN_LOG_START
  | typeof ASSIGN_LOG_SUCCESS
  | typeof ASSIGN_LOG_FAIL
  | typeof ASSIGN_LOG_RESET
  | typeof LOGOUT;
