export const FETCH_ELDS_LIST_START = 'FETCH_ELDS_LIST_START';
export const FETCH_ELDS_LIST_SUCCESS = 'FETCH_ELDS_LIST_SUCCESS';
export const FETCH_ELDS_LIST_FAIL = 'FETCH_ELDS_LIST_FAIL';

export const FETCH_ALL_ELDS_START = 'FETCH_ALL_ELDS_START';
export const FETCH_ALL_ELDS_SUCCESS = 'FETCH_ALL_ELDS_SUCCESS';
export const FETCH_ALL_ELDS_FAIL = 'FETCH_ALL_ELDS_FAIL';

export const FETCH_ELD_HISTORY_START = 'FETCH_ELD_HISTORY_START';
export const FETCH_ELD_HISTORY_SUCCESS = 'FETCH_ELD_HISTORY_SUCCESS';
export const FETCH_ELD_HISTORY_FAIL = 'FETCH_ELD_HISTORY_FAIL';

export const RESET_ELD_HISTORY_STORE = 'RESET_ELD_HISTORY_STORE';
export const RESET_ELD_STORE = 'RESET_ELD_STORE';

export const LOGOUT = 'LOGOUT';

export type EldActionTypes =
  | typeof FETCH_ELDS_LIST_START
  | typeof FETCH_ELDS_LIST_SUCCESS
  | typeof FETCH_ELDS_LIST_FAIL
  | typeof FETCH_ALL_ELDS_START
  | typeof FETCH_ALL_ELDS_SUCCESS
  | typeof FETCH_ALL_ELDS_FAIL
  | typeof FETCH_ELD_HISTORY_START
  | typeof FETCH_ELD_HISTORY_SUCCESS
  | typeof FETCH_ELD_HISTORY_FAIL
  | typeof RESET_ELD_HISTORY_STORE
  | typeof RESET_ELD_STORE
  | typeof LOGOUT;
