import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import {
  createCompanyStart,
  createCompanySuccess,
  createCompanyFail,
  fetchCompaniesStart,
  fetchCompaniesSuccess,
  fetchCompaniesFail,
  fetchCompanyStart,
  fetchCompanySuccess,
  updateCompanyStart,
  fetchCompanyFail,
  updateCompanySuccess,
  updateCompanyFail,
  fetchCompanyOptionsStart,
  fetchCompanyOptionsSuccess,
  fetchCompanyOptionsFail,
  deleteCompanyStart,
  deleteCompanySuccess,
  deleteCompanyFail,
  changeCompanyStatusStart,
  changeCompanyStatusSuccess,
  changeCompanyStatusFail,
} from './actions';
import { showToast } from '../../utility/toast/toast';
import { IntlShape } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';

const API_URL = '/companies';

export type CompanyCreateRequest = {
  name: string;
  usdot: string;
};

export type CompanyUpdateRequest = {
  id: number;
  name: string;
  address: string;
  usdot: string;
  timezone: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email?: string;
  drivingSpeed: number;
  warningBreakViolation: number;
  warningDriveViolation: number;
  warningShiftViolation: number;
  warningCycleViolation: number;
};

export const fetchCompanies =
  (params: ListParams, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchCompaniesStart());
    return axios
      .get(API_URL, { params })
      .then((response) => {
        dispatch(fetchCompaniesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchCompaniesFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const fetchCompanyOptions =
  (intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchCompanyOptionsStart());
    return axios
      .get(`${API_URL}/options`)
      .then((response) => {
        dispatch(fetchCompanyOptionsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchCompanyOptionsFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const fetchCompany =
  (companyId: number, intl?: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchCompanyStart());
    return axios
      .get(`${API_URL}/${companyId}`)
      .then((response) => {
        dispatch(fetchCompanySuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchCompanyFail(err?.response?.data?.message));

        if (intl) {
          showToast(translate(intl, err?.response?.data?.message), 'error');
        }
      });
  };

export const createCompany =
  (inputs: CompanyCreateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(createCompanyStart());
    return axios
      .post(API_URL, { ...inputs })
      .then((response) => {
        dispatch(createCompanySuccess(response.data));
        showToast(translate(intl, 'SUCCESS.COMPANY_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(
          createCompanyFail(translate(intl, err?.response?.data?.message)),
        );
      });
  };

export const updateCompany =
  (inputs: CompanyUpdateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateCompanyStart());
    return axios
      .patch(`${API_URL}/${inputs.id}`, { ...inputs })
      .then((response) => {
        dispatch(updateCompanySuccess(response.data));
        showToast(translate(intl, 'SUCCESS.COMPANY_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(
          updateCompanyFail(translate(intl, err?.response?.data?.message)),
        );
      });
  };

export const changeCompanyStatus =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(changeCompanyStatusStart());
    return axios
      .patch(`${API_URL}/${id}/status`)
      .then(() => {
        dispatch(changeCompanyStatusSuccess());
        showToast(translate(intl, 'SUCCESS.COMPANY_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(
          changeCompanyStatusFail(
            translate(intl, err?.response?.data?.message),
          ),
        );
      });
  };

export const updateOwnerCompany =
  (inputs: CompanyUpdateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateCompanyStart());
    return axios
      .patch(`${API_URL}/owner`, { ...inputs })
      .then((response) => {
        dispatch(updateCompanySuccess(response.data));
        showToast(translate(intl, 'SUCCESS.COMPANY_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(
          updateCompanyFail(translate(intl, err?.response?.data?.message)),
        );
      });
  };

export const deleteCompany =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteCompanyStart());
    return axios
      .delete(`${API_URL}/${id}`)
      .then(() => {
        dispatch(deleteCompanySuccess());
        showToast(translate(intl, 'SUCCESS.COMPANY_DELETE'), 'success');
      })
      .catch((err) => {
        dispatch(
          deleteCompanyFail(translate(intl, err?.response?.data?.message)),
        );
      });
  };
